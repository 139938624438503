@keyframes fadeInDownSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -25%, 0);
        transform: translate3d(0, -25%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDownSmall {
    -webkit-animation-name: fadeInDownSmall;
    animation-name: fadeInDownSmall;
}
@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 25%, 0);
        transform: translate3d(0, 25%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUpSmall {
    -webkit-animation-name: fadeInUpSmall;
    animation-name: fadeInUpSmall;
}
@keyframes fadeInLeftSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-25%, 0, 0);
        transform: translate3d(-25%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInLeftSmall {
    -webkit-animation-name: fadeInLeftSmall;
    animation-name: fadeInLeftSmall;
}
@keyframes fadeInRightSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(25%, 0, 0);
        transform: translate3d(25%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInRightSmall {
    -webkit-animation-name: fadeInRightSmall;
    animation-name: fadeInRightSmall;
}
