/* ==== GENERAL ==== */
a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
td,
th {
    font-family: $circular;
    line-height: 1.3;
    font-weight: 300;
}

body,
html {
    width: 100vw;
    overflow-x: hidden;
}

body {
    &.lock {
        @media screen and (max-width: 639px) {
            overflow: hidden;
        }
    }
}

.grid-container {
    max-width: none;
    padding: 0 5%;

    .grid-container {
        padding: 0;
    }
}

:focus {
    outline: none!important;
}

p {
    color: $black;
}
/* ==== HEADER ==== */
header {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 60px;
    top: 0;
    z-index: 999;
    background: rgba(0,0,0,0);
    transition: all 0.5s ease;
    @media screen and (max-width: 639px) {
        padding-top: 30px;
    }

    &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, .85) 0%, rgba(0, 0, 0, .5) 48%, rgba(0, 0, 0, 0) 100%);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    &.home {
        animation-delay: 1s;
    }

    &.bg,
    &.gradient {
        padding-bottom: 60px;

        &::before {
            opacity: 1;
        }
    }

    &.scroll {
        padding: 30px 0 60px;
        top: 0;

        &::before {
            opacity: 1;
        }

        h1 {
            a {
                img {
                    max-width: 200px;
                    @media screen and (max-width: 639px) {
                        max-width: 150px;
                    }
                }
            }
        }
    }

    h1 {
        margin: 0;
        font-size: 0;
        line-height: 1;

        a {
            img {
                max-width: 250px;
                transition: max-width 0.5s ease;
                display: block;
                @media screen and (max-width: 639px) {
                    max-width: 180px;
                }
            }
        }
    }

    nav {
        transition: opacity 0.5s ease 0.75s;
        @media screen and (max-width: 768px) {
            display: none;
        }

        &.fade {
            opacity: 0;
            pointer-events: none;
            z-index: -1;
            transition: opacity 0.5s ease;
        }

        ul {
            display: inline-block;

            li {
                a {
                    font-size: 16px;
                }
            }
        }
    }

    .hamburgerWrapper {
        text-align: right;
        padding-top: 7px;
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
        }
        @media screen and (max-width: 639px) {
            padding-top: 5px;
        }

        .hamburger--squeeze {
            .hamburger-inner {
                transition-duration: 0.25s;

                &::after,
                &::before {
                    transition-duration: 0.25s;
                }
            }
        }

        .hamburger {
            padding: 0;
            margin: 0;
            @media screen and (max-width: 639px) {
                transform: scale(0.8);
            }

            .hamburger-inner {
                background: #fff;
                height: 2px;

                &::after,
                &::before {
                    background: #fff;
                    height: 2px;
                }
            }
        }
    }
}
/* ==== NAV ==== */
nav {
    text-align: right;
    position: relative;

    ul {
        margin: 0;
        list-style: none;

        li {
            display: inline-block;
            float: left;
            margin-left: 60px;
            text-align: left;
            @media screen and (max-width: 1023px) {
                margin-left: 40px;
            }

            a {
                color: $red;
                transition: color 0.5s ease;
                letter-spacing: -0.03125rem;
                line-height: 1.1;
                font-weight: 500;
                font-size: 20px;

                span {
                    color: #fff;
                    display: block;
                }

                &:hover {
                    color: #fff;
                }
            }

            ul {
                margin-bottom: 30px;
                border-top: 1px solid #fff;
                margin-top: 10px;
                padding-top: 10px;
                column-count: 2;
                display: none;

                li {
                    margin-bottom: 4px;

                    a {
                        font-weight: 300;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.sideBar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 33.33333333%;
    background: $red;
    z-index: 99;
    padding: 0 5% 35px;
    transform: translateX(100%);
    transition: transform 1.25s cubic-bezier(0.86, 0, 0.07, 1);
    @media screen and (max-width: 768px) {
        width: 50%;
    }
    @media screen and (max-width: 639px) {
        padding: 0 25px 20px;
        width: 100%;
    }

    &.shift {
        transform: none;
    }

    .sideBarWrapper {
        height: 100%;
        padding-top: 150px;
        min-height: 725px;
        overflow: hidden;
        position: relative;
        @media screen and (max-width: 1279px) {
            padding-top: 150px;
        }
        @media screen and (max-width: 1023px) {
            min-height: 600px;
        }
        @media screen and (max-width: 639px) {
            min-height: 0;
        }

        .mCSB_inside > .mCSB_container {
            height: 100%;
        }

        nav {
            text-align: left;

            ul {
                li {
                    float: none;
                    display: block;
                    margin: 0 0 15px;

                    a {
                        color: $darkGrey;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .sideBarFooter {
            position: absolute;
            bottom: 0;
            left: -15px;
            right: -15px;

            img {
                &#canmoor {
                    margin-left: 10%;
                    position: relative;
                    top: 12%;
                    @media screen and (max-width: 768px) {
                        width: 85%;
                    }
                    @media screen and (max-width: 639px) {
                        top: 10%;
                        width: 80%;
                    }
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                @media screen and (max-width: 639px) {
                    width: 95%;
                    margin: 0 auto;
                }
            }
        }
    }
}
/* ==== HOME ==== */
.hero {
    @include bgCover;
    @include fullHeight;
    position: relative;
    @media screen and (max-width: 1023px) {
         @include fullHeight($min-height:400px);
    }

    &.contact {
        min-height: 825px;
        background-position: 65% center;
        @media screen and (max-width: 639px) {
            height: auto;
            min-height: 0;
        }
    }

    .down {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;

        &.home {
            animation-delay: 1s;
        }
        @media screen and (max-width: 639px) {
            bottom: 30px;
        }

        &.right {
            text-align: right;
        }

        img {
            width: 50px;
            @media screen and (max-width: 639px) {
                width: 35px;
            }
        }
    }

    .heroContent {
        @include vAlign;
        animation-duration: 2s;

        &.paroller {
            top: 10%;
        }

        &.extraTop {
            top: 35%;
        }

        &.main {
            top: 45%;
        }

        h2 {
            color: $red;
            font-weight: 700;
            font-size: 96px;
            letter-spacing: -3px;
            line-height: 0.9;
            @media screen and (max-width: 1366px) {
                font-size: 72px;
                letter-spacing: -2px;
            }
            @media screen and (max-width: 1023px) {
                font-size: 64px;
                letter-spacing: -1px;
            }
            @media screen and (max-width: 639px) {
                font-size: 48px;
                letter-spacing: 0;
            }

            span {
                color: #fff;
            }
        }

        .heroText {
            margin-top: 60px;
            @media screen and (max-width: 1023px) {
                margin-top: 50px;
            }
            @media screen and (max-width: 639px) {
                margin-top: 35px;
            }

            p {
                color: #fff;
                font-size: 18px;
                line-height: 1.3;
                font-weight: 300;
                @media screen and (max-width: 1279px) {
                    font-size: 16px;
                }
            }

            a {
                color: #fff;
                display: inline-block;
                margin-top: 40px;
                padding: 25px 55px;
                border: 1px solid $red;
                transition: background 0.3s ease;
                @media screen and (max-width: 1279px) {
                    padding: 15px 30px;
                }
                @media screen and (max-width: 1023px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 639px) {
                    font-size: 12px;
                }

                &:hover {
                    background: $red;
                }

                .fa {
                    padding-left: 10px;
                }
            }
        }

        &.static {
            position: static;
            transform: none;
            padding-top: 75px;
            @media screen and (max-width: 639px) {
                padding-top: 0;
            }

            h2 {
                margin-bottom: 75px;
                @media screen and (max-width: 1279px) {
                    margin-bottom: 50px;
                }

                span {
                    color: $black;
                }
            }

            img {
                width: 285px;
                @media screen and (max-width: 1279px) {
                    width: 200px;
                }
            }

            .contacts {
                margin-top: 35px;

                p {
                    font-size: 18px;
                    color: $black;
                    @media screen and (max-width: 1279px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 639px) {
                        font-size: 14px;
                    }

                    a {
                        color: $black;
                        transition: color 0.3s ease;

                        &:hover {
                            color: $red;
                        }
                    }

                    &.intro,
                    strong {
                        font-weight: 500;
                    }

                    &.intro {
                        margin-bottom: 28px;
                    }
                }
            }

            a.download {
                color: $red;
                display: inline-block;
                margin-top: 50px;
                font-size: 32px;
                font-weight: 500;
                @media screen and (max-width: 1023px) {
                    font-size: 24px;
                    margin-top: 25px;
                }
                @media screen and (max-width: 639px) {
                    font-size: 21px;
                    margin-top: 15px;
                }
            }
        }
    }

    .spin {
        position: absolute;
        left: -5%;
        top: 45%;
        transform: translateY(0) rotate(90deg);
        @media screen and (max-width: 1023px) {
            left: -20%;
        }
        @media screen and (max-width: 769px) {
            left: -25%;
        }
        @media screen and (max-width: 639px) {
            transform: none;
            top: 150px;
            left: 0;
        }

        .grid-container {
            padding: 0;
            @media screen and (max-width: 639px) {
                padding: 0 30px;
            }
        }

        h2 {
            color: $red;
            font-weight: 700;
            font-size: 84px;
            line-height: 1;
            letter-spacing: -3px;
            line-height: 0.9;
            @media screen and (max-width: 1366px) {
                font-size: 72px;
                letter-spacing: -2px;
            }
            @media screen and (max-width: 1023px) {
                font-size: 64px;
                letter-spacing: -1px;
            }
            @media screen and (max-width: 639px) {
                font-size: 48px;
                white-space: nowrap;
                letter-spacing: 0;
            }

            span {
                color: #fff;
            }
        }
    }
}
/* ==== CHANGE YOUR STYLE ==== */
.hero .heroContent.paroller.internal {
    top: 25%;
    @media screen and (max-width: 1023px) {
        top: 33%;
    }

    h1 {
        color: $red;
        font-weight: 700;
        font-size: 96px;
        letter-spacing: -3px;
        line-height: 1;
        @media screen and (max-width: 1366px) {
            font-size: 72px;
            letter-spacing: -2px;
        }
        @media screen and (max-width: 1023px) {
            font-size: 64px;
            letter-spacing: -1px;
        }
        @media screen and (max-width: 639px) {
            font-size: 48px;
            letter-spacing: 0;
        }

        span {
            color: #fff;
        }
    }
}

.textSection {
    padding: 75px 0;
    @media screen and (max-width: 1023px) {
        padding: 50px 0;
    }
    @media screen and (max-width: 639px) {
        padding: 35px 0;
    }

    &.red {
        background: $red;
    }

    h2 {
        color: $red;
        text-align: center;
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 60px;
        @media screen and (max-width: 1023px) {
            font-size: 24PX;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 768px) {
            font-size: 21PX;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 639px) {
            font-size: 18px;
            margin-bottom: 35px;
        }
    }

    .townSliderContainer {
        position: relative;

        .controls {
            @include vAlign($position: absolute);
            left: 0;
            right: 0;

            .grid-container {
                img {
                    width: 25px;
                    cursor: pointer;
                    position: relative;
                    @media screen and (max-width: 768px) {
                        width: 20px;
                    }
                    @media screen and (max-width: 639px) {
                        width: 15px;
                    }

                    &#prev {
                        margin-left: -45px;
                        @media screen and (max-width: 1023px) {
                            margin-left: -35px;
                        }
                        @media screen and (max-width: 768px) {
                            margin-left: -25px;
                        }
                    }

                    &#next {
                        margin-right: -45px;
                        @media screen and (max-width: 1023px) {
                            margin-right: -35px;
                        }
                        @media screen and (max-width: 768px) {
                            margin-right: -25px;
                        }
                    }

                    &#prevTwo {
                        @media screen and (max-width: 639px) {
                            margin-left: -15px;
                        }
                    }

                    &#nextTwo {
                        @media screen and (max-width: 639px) {
                            margin-right: -15px;
                        }
                    }
                }
            }
        }

        .townSlider {
            margin-bottom: 65px;
            @media screen and (max-width: 1023px) {
                margin-bottom: 50px;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 30px;
            }

            .townSlide {
                padding: 0 15px;
                height: 400px;
                @media screen and (max-width: 768px) {
                    height: 350px;
                }
                @media screen and (max-width: 639px) {
                    height: auto;
                }

                img {
                    height: 100%;
                    width: auto;
                    @media screen and (max-width: 639px) {
                        height: auto;
                    }

                    &.half {
                        height: calc(50% - 15px);
                        @media screen and (max-width: 639px) {
                            height: auto;
                        }

                        &:first-child {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }

    .leftText {
        text-align: right;
        @media screen and (max-width: 639px) {
            text-align: left;
        }

        p.source {
            color: $red;
            font-size: 10px;
        }
    }
}

#boxpark {
    img {
        margin-bottom: 30px;
    }

    .bottom {
        position: relative;

        img {
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px;
            width: calc(100% - 30px);
            @media screen and (max-width: 639px) {
                width: 100%;
                position: static;
            }
        }
    }

    .bottomMedium {
        position: relative;
        @media screen and (max-width: 1023px) {
            img {
                position: absolute;
                bottom: 0;
                left: 15px;
                right: 15px;
                width: calc(100% - 30px);
                @media screen and (max-width: 639px) {
                    width: 100%;
                    position: static;
                }
            }
        }
    }

    #boxparklogo {
        margin: 60px 0 50px;
        width: 290px;
        @media screen and (max-width: 1023px) {
            margin-top: 30px;
        }
        @media screen and (max-width: 768px) {
            width: 250px;
            margin-bottom: 30px;
        }
        @media screen and (max-width: 639px) {
            width: 200px;
            margin-bottom: 30px;
            margin-top: 0;
        }
    }

    p {
        padding-right: 30px;
        @media screen and (max-width: 1023px) {
            margin-bottom: 50px;
        }
        @media screen and (max-width: 639px) {
            margin-bottom: 30px;
        }

        &.lead {
            font-size: 21px;
            line-height: 1.3;
            @media screen and (max-width: 1023px) {
                margin-bottom: 1rem;
            }
            @media screen and (max-width: 768px) {
                font-size: 19px;
            }
            @media screen and (max-width: 639px) {
                font-size: 18px;
            }
        }
    }
}

#the-croydon-partnership {
    position: relative;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .grid-x {
            height: 100%;

            .cell {
                height: 100%;
                @media screen and (max-width: 639px) {
                    height: 50%;
                }

                .imgCell {
                    height: 100%;
                    @include bgCover;
                }
            }
        }
    }

    .centerContent {
        position: relative;
        z-index: 99;
        padding: 300px 0;
        @media screen and (max-width: 1023px) {
            padding: 225px 0;
        }
        @media screen and (max-width: 768px) {
            padding: 200px 0;
        }
        @media screen and (max-width: 639px) {
            padding: 150px 0;
        }

        h2 {
            color: $red;
            text-align: center;
            font-weight: 500;
            font-size: 28px;
            background: $black;
            margin: 0;
            padding: 40px 45px;
            @media screen and (max-width: 1023px) {
                font-size: 24px;
                padding: 35px;
            }
            @media screen and (max-width: 768px) {
                font-size: 21px;
            }
            @media screen and (max-width: 639px) {
                font-size: 18px;
                padding: 25px;
            }
        }
    }
}

#local-map {
    background: $black;

    .grid-container {
        padding-right: 0;
        @media screen and (max-width: 1023px) {
            padding: 0 5%;
        }

        .mapText {
            padding: 75px 0 30px;
            @media screen and (max-width: 768px) {
                padding-top: 40px;
            }
            @media screen and (max-width: 639px) {
                padding: 25px 0 40px;
            }

            p {
                color: #3f3f3f;
                font-weight: 500;
                font-size: 21px;
                @media screen and (max-width: 639px) {
                    font-size: 18px;
                }
            }

            ol {
                list-style: none;
                margin: 25px 0;
                @media screen and (max-width: 1023px) {
                    column-count: 2;
                    margin: 35px 0;
                }
                @media screen and (max-width: 639px) {
                    column-count: 1;
                }

                li {
                    color: #fff;
                    counter-increment: item;
                    margin-bottom: 10px;

                    &::before {
                        margin-right: 10px;
                        content: counter(item);
                        border: 1px solid $red;
                        color: white;
                        width: 21px;
                        text-align: center;
                        display: inline-block;
                    }
                }
            }

            img {
                width: 325px;
            }
        }

        #map {
            @include vAlign;
            @media screen and (max-width: 1023px) {
                width: 110%;
                max-width: 110%;
                margin-left: -5%;
            }
        }
    }
}
/* ==== CHANGE YOUR DIRECTION ==== */
.hero .heroContent.paroller.internal.two {
    top: 33%;
}

.imgSection {
    @include bgCover;
    padding: 100px;
    @media screen and (max-width: 1279px) {
        padding: 100px 0 75px;
    }
    @media screen and (max-width: 1023px) {
        padding: 75px 0;
    }
    @media screen and (max-width: 639px) {
        padding: 40px 0;
    }

    hr {
        margin: 0 auto;
        width: 125px;
        max-width: 125px;
        border-bottom: 1px solid $red;
    }

    h2 {
        color: #666666;
        font-size: 36px;
        margin: 50px 0;
        font-weight: 500;
        @media screen and (max-width: 1279px) {
            font-size: 32px;
        }
        @media screen and (max-width: 1023px) {
            font-size: 28px;
        }
        @media screen and (max-width: 768px) {
            font-size: 26px;
        }
        @media screen and (max-width: 639px) {
            font-size: 21px;
            margin: 30px 0 40px;
        }
    }

    p {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        margin: 0 0 2px;
        animation-duration: 1.5s;
        @media screen and (max-width: 1279px) {
            font-size: 21px;
        }
        @media screen and (max-width: 1023px) {
            font-size: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 19px;
        }
        @media screen and (max-width: 639px) {
            font-size: 16px;
            margin-bottom: 10px;
        }

        &.two {
            animation-delay: 0.6s;
        }

        &.three {
            animation-delay: 1.2s;
        }

        &.four {
            animation-delay: 1.8s;
        }

        &.five {
            margin-bottom: 50px;
            animation-delay: 2.4s;
            @media screen and (max-width: 639px) {
                margin-bottom: 30px;
            }
        }

        &.cite {
            font-weight: 300;
            font-size: 12px;
            color: #666666;
            margin-bottom: 75px;
            animation-delay: 3s;
            @media screen and (max-width: 1023px) {
                margin-bottom: 50px;
            }
            @media screen and (max-width: 639px) {
                animation-delay: 0s;
                margin-bottom: 30px;
            }
        }
    }
}

#travel-times {
    h2 {
        color: $black;
        font-size: 36px;
        @media screen and (max-width: 1279px) {
            font-size: 32px;
        }
        @media screen and (max-width: 1023px) {
            font-size: 28px;
        }
        @media screen and (max-width: 768px) {
            font-size: 26px;
        }
        @media screen and (max-width: 639px) {
            font-size: 21px;
        }
    }

    .travelGraphic {
        .grid-x {
            margin-bottom: 50px;
            @media screen and (max-width: 768px) {
                margin-bottom: 30px;
            }

            &:last-child {
                margin-bottom: 0;
                @media screen and (max-width: 1023px) {
                    margin-bottom: 10px;
                }
            }
        }

        .main {
            @media screen and (max-width: 1023px) {
                margin-bottom: 15px;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 5px;
            }

            * {
                color: #fff;
            }

            img {
                width: 45px;
                margin-bottom: 10px;

                &#car {
                    width: 40px;
                }
            }

            h3 {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
                @media screen and (max-width: 1279px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 1023px) {
                    font-size: 16px;
                }
            }

            h4 {
                font-weight: 300;
                font-size: 12px;
                margin: 0;
            }
        }

        .travelInfo {
            @include vAlign;

            * {
                color: $black;
            }

            h4 {
                font-size: 18px;
                font-weight: 300;
                margin: 0;
                @media screen and (max-width: 1279px) {
                    font-size: 16px;
                }
            }

            p {
                font-size: 12px;
                margin: 0;
                @media screen and (max-width: 639px) {
                    margin-bottom: 10px;
                }
            }
        }

        .lineWrapper {
            position: relative;
            @include vAlign;
            padding: 0 15px;

            &.middle {
                animation-delay: 0.5s;
                @media screen and (max-width: 639px) {
                    animation-delay: 0s;
                }
            }

            &.outer {
                animation-delay: 1.5s;
                @media screen and (max-width: 639px) {
                    animation-delay: 0s;
                }
            }

            img {
                @include vAlign($position: absolute);
                width: 12px;

                &.left {
                    left: 5px;
                }

                &.right {
                    right: 5px;
                }
            }

            .line {
                height: 2px;
                background: #ffa095;
            }
        }

        .middle {
            animation-delay: 1s;
            @media screen and (max-width: 639px) {
                animation-delay: 0s;
            }
        }

        .outer {
            animation-delay: 2s;
            @media screen and (max-width: 639px) {
                animation-delay: 0s;
            }
        }
    }
}

#economics {
    @media screen and (max-width: 1279px) {
        padding-top: 75px;
    }
    @media screen and (max-width: 1023px) {
        padding-bottom: 25px;
        padding-top: 50px;
    }
    @media screen and (max-width: 639px) {
        padding-top: 30px;
        padding-bottom: 10px;
    }

    h2 {
        color: $black;
        font-size: 90px;
        margin: 0 0 100px;
        @media screen and (max-width: 1279px) {
            font-size: 72px;
        }
        @media screen and (max-width: 1023px) {
            font-size: 64px;
            margin-bottom: 75px;
        }
        @media screen and (max-width: 768px) {
            font-size: 54px;
            margin-bottom: 60px;
        }
        @media screen and (max-width: 639px) {
            font-size: 42px;
            margin-bottom: 40px;
        }
    }

    .imgContainer {
        img {
            max-width: 125px;
            max-height: 140px;
            @include vAlign;
            @media screen and (max-width: 768px) {
                max-width: 100px;
                max-height: 125px;
            }
            @media screen and (max-width: 639px) {
                position: static;
                transform: none;
                max-width: 125px;
                max-height: 140px;
            }
        }
    }

    hr {
        width: 15px;
        max-width: 15px;
        border-bottom: 2px solid white;
        margin: 40px auto;
        @media screen and (max-width: 1279px) {
            margin: 40px auto 30px;
        }
        @media screen and (max-width: 768px) {
            margin: 25px auto;
        }
        @media screen and (max-width: 639px) {
            width: 25px;
        }
    }

    p {
        font-weight: 500;
        font-size: 16px;
        padding: 0 15px;
        @media screen and (max-width: 1279px) {
            font-size: 14px;
        }
        @media screen and (max-width: 1023px) {
            margin-bottom: 60px;
        }
        @media screen and (max-width: 639px) {
            margin-bottom: 40px;
        }

        strong {
            font-weight: 700;
        }
    }
}
/* ==== CHANGE YOUR VIEW ==== */
.hero.view {
    background-image: url('../img/heroview.jpg');
    @media screen and (max-width: 639px) {
        background-image: url('../img/hero4.jpg');
    }

    .heroContent.paroller.internal {
        top: 33%;
        @media screen and (max-width: 1023px) {
            top: 45%;
        }
    }
}

#description {
    img {
        filter: grayscale(100%);
        transition: filter 0.4s ease;
        margin-bottom: 30px;

        &:hover {
            filter: none;
        }
    }

    h2 {
        color: $black;
        text-align: left;
        margin-top: 100px;
        padding-right: 25px;
        font-size: 32px;
        @media screen and (max-width: 1279px) {
            font-size: 28px;
            margin-top: 75px;
        }
        @media screen and (max-width: 1023px) {
            font-size: 26px;
            margin-top: 15px;
        }
        @media screen and (max-width: 639px) {
            font-size: 21px;
            margin-top: 15px;
        }
    }
}

#specification {
    background: $black;

    h2 {
        &:first-child {
            margin: 50px 0 25px;
        }

        &:last-of-type {
            margin: 0 0 50px;
        }
    }

    ul.speclist {
        column-count: 3;
        margin: 75px 0 0;
        @media screen and (max-width: 1023px) {
            column-count: 2;
            margin-top: 50px;
        }
        @media screen and (max-width: 639px) {
            column-count: 1;
            margin: 35px 0 0;
        }

        li {
            list-style-position: inside;
            color: $red;
            text-indent: -22px;
            padding-left: 15px;
            margin-bottom: 3px;

            span {
                color: #fff;
            }
        }
    }
}

#accommodation {
    h2 {
        color: $black;
        font-size: 90px;
        margin: 0 0 80px;
        text-align: right;
        @media screen and (max-width: 1279px) {
            font-size: 64px;
        }
        @media screen and (max-width: 1023px) {
            font-size: 64px;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 768px) {
            font-size: 48px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 639px) {
            font-size: 36px;
            margin-bottom: 25px;
            text-align: center;
        }
    }

    table {
        background: none;
        border: none;

        thead {
            background: none;
            border: none;
            border-bottom: 2px solid $black;

            th {
                padding: 0 0 8px;

                &:last-child,
                &:nth-child(3) {
                    text-align: right;
                }
                @media screen and (max-width: 639px) {
                    font-size: 14px;
                }
            }
        }

        tfoot {
            background: none;
            border: none;

            td {
                padding: 8px 0 0;

                &:last-child,
                &:nth-child(3) {
                    text-align: right;
                }
                @media screen and (max-width: 639px) {
                    font-size: 14px;
                }
            }
        }

        tbody {
            background: none;
            border: none;

            tr {
                &:nth-child(even) {
                    background: none;
                    border: none;
                }

                &:last-child {
                    td {
                        border-bottom: 2px solid $black;
                    }
                }

                &.toLet {
                    td {
                        color: #fff;
                        font-weight: 500;
                    }
                }

                td {
                    padding: 8px 0;
                    border-bottom: 1px solid $black;

                    &:last-child,
                    &:nth-child(3) {
                        text-align: right;
                    }
                    @media screen and (max-width: 639px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    img {
        margin-left: 10%;
        @media screen and (max-width: 1023px) {
            width: 75%;
            display: block;
            margin: 0 auto 65px;
        }
        @media screen and (max-width: 768px) {
            width: 85%;
        }
        @media screen and (max-width: 639px) {
            width: 100%;
            margin-bottom: 40px;
        }
    }
}

#space-plans {
    h2 {
        color: $black;
        margin: 0;
    }

    .planSliderContainer {
        position: relative;

        .controls {
            @include vAlign($position: absolute);
            left: 0;
            right: 0;
            z-index: 99;

            .grid-container {
                img {
                    width: 25px;
                    cursor: pointer;
                    position: relative;
                    @media screen and (max-width: 768px) {
                        width: 20px;
                    }
                    @media screen and (max-width: 639px) {
                        width: 15px;
                    }

                    &#prevRed {
                        margin-left: -45px;
                        @media screen and (max-width: 1023px) {
                            margin-left: -35px;
                        }
                        @media screen and (max-width: 768px) {
                            margin-left: -25px;
                        }
                    }

                    &#nextRed {
                        margin-right: -45px;
                        @media screen and (max-width: 1023px) {
                            margin-left: -15px;
                        }
                        @media screen and (max-width: 768px) {
                            margin-right: -15px;
                        }
                    }
                }
            }
        }

        .planSlider {
            h3 {
                text-align: center;
                color: $red;
                font-weight: 700;
                font-size: 78px;
                margin: 100px 0 150px;
                @media screen and (max-width: 1279px) {
                    font-size: 48px;
                    margin: 75px 0 100px;
                }
                @media screen and (max-width: 1023px) {
                    font-size: 42px;
                    margin: 50px 0;
                }
                @media screen and (max-width: 639px) {
                    font-size: 36px;
                    margin: 50px 0 35px;
                }
            }

            table {
                background: none;
                border: none;
                margin-bottom: 30px;
                @media screen and (max-width: 1023px) {
                    width: 75%;
                    margin: 0 auto 30px;
                }
                @media screen and (max-width: 768px) {
                    width: 85%;
                }
                @media screen and (max-width: 639px) {
                    width: 100%;
                }

                thead {
                    background: none;
                    border: none;

                    th {
                        border-top: 1px solid $black;
                        padding: 8px 0;
                        @media screen and (max-width: 1279px) {
                            font-size: 14px;
                        }
                        @media screen and (max-width: 1023px) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 639px) {
                            font-size: 14px;
                        }
                    }
                }

                tbody {
                    background: none;
                    border: none;

                    tr {
                        &:nth-child(even) {
                            background: none;
                            border: none;
                        }

                        &:last-child {
                            td {
                                border-bottom: 1px solid $black;
                            }
                        }

                        td {
                            border-top: 1px solid $black;
                            padding: 8px 0;
                            text-align: right;
                            @media screen and (max-width: 1279px) {
                                font-size: 14px;
                            }
                            @media screen and (max-width: 1023px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 639px) {
                                font-size: 14px;
                            }

                            &:first-child {
                                text-align: left;
                            }
                        }
                    }
                }
            }

            img {
                width: 75%;
                margin: 0 auto;
                display: block;
                @include vAlign;
                @media screen and (max-width: 1279px) {
                    width: 90%;
                }
                @media screen and (max-width: 1023px) {
                    width: 75%;
                    margin: 30px auto 0;
                }
                @media screen and (max-width: 639px) {
                    width: 85%;
                    margin: 10px auto 0;
                }
            }
        }
    }
}

.gallery {
    position: relative;
    margin-bottom: -7px;

    .title {
        position: absolute;
        left: 0;
        top: 50px;
        z-index: 999;
        width: 100%;
        @media screen and (max-width: 768px) {
            top: 30px;
        }
        @media screen and (max-width: 639px) {
            top: 20px;
        }

        h2 {
            color: #fff;
            font-size: 72px;
            font-weight: 500;
            text-shadow: 0 0 10px rgba($black,.75);
            @media screen and (max-width: 1279px) {
                font-size: 64px;
            }
            @media screen and (max-width: 1023px) {
                font-size: 52px;
            }
            @media screen and (max-width: 768px) {
                font-size: 42px;
            }
            @media screen and (max-width: 639px) {
                font-size: 28px;
            }
        }
    }

    .controls {
        @include vAlign($position: absolute);
        left: 0;
        right: 0;
        z-index: 99;

        .grid-container {
            img {
                width: 25px;
                cursor: pointer;
                position: relative;
                @media screen and (max-width: 768px) {
                    width: 20px;
                }
                @media screen and (max-width: 639px) {
                    width: 15px;
                }

                &#prev {
                    margin-left: -45px;
                    @media screen and (max-width: 1023px) {
                        margin-left: -35px;
                    }
                    @media screen and (max-width: 768px) {
                        margin-left: -25px;
                    }
                }

                &#next {
                    margin-right: -45px;
                    @media screen and (max-width: 1023px) {
                        margin-left: -15px;
                    }
                    @media screen and (max-width: 768px) {
                        margin-right: -15px;
                    }
                }
            }
        }
    }

    .gallerySlider {
        img {
            filter: grayscale(100%);
            transition: filter 0.5s ease 0.1s;

            &.colour {
                filter: none;
            }
        }

        .double img {
            width: 50%;
            float: left;
            height: auto;
        }
    }
}
/* ==== TIME FOR A change ==== */
.hero {
    .heroContent.static.internal {
        padding-top: 150px;
        @media screen and (max-width: 639px) {
            padding-top: 75px;
            margin-bottom: 50px;
        }
    }
}
/* ==== FOOTER ==== */
footer {
    background: #474747;
    padding: 30px 0;

    &.home {
        background: transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        @media screen and (max-width: 639px) {
            position: static;
            margin-top: 5px;
            padding-bottom: 15px;
        }
    }

    a.credit,
    p.disclaimer {
        font-size: 10px;
        color: #fff;
    }

    a.credit {
        font-weight: 500;
        transition: color 0.3s ease;
        @media screen and (max-width: 1023px) {
            display: inline-block;
            margin-bottom: 25px;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }

        &:hover {
            color: $red;
        }
    }

    img {
        display: block;
        width: 85%;
        margin: 0 auto;
        @media screen and (max-width: 1279px) {
            width: 95%;
        }

        &#canmoor {
            @media screen and (max-width: 639px) {
                margin-left: 5%;
                position: relative;
                top: 1%;
            }
        }
    }
}
