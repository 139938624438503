$circular: 'Circular Std', sans-serif;
$red: #f4333f;
$darkGrey: #282828;
$black: #0c0c0c;
@mixin vAlign($position:relative,$distance:-50%) {
	position: $position;
	top: 50%;
	transform: translateY($distance);
}
@mixin bgCover($size:cover, $position: center) {
	background-size: $size;
	background-position: $position;
	background-repeat: no-repeat;
}
@mixin fullHeight($height:100vh,$min-height:696px) {
	height: $height;
	min-height: $min-height;
}
