@font-face {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/CircularStd-Book.woff') format('woff');
}
@font-face {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/CircularStd-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/CircularStd-Bold.woff') format('woff');
}
